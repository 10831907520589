import React, {useEffect, useMemo, useState} from "react"

import {
	Col,
	Row,
	Card, CardBody, CardTitle, CardText, TabPane, NavLink, NavItem, TabContent, Nav,
	Collapse,
} from "reactstrap"

//import { registrationFormScreens } from "./Configuration"

import {useOvermind, useOvermindForm} from "../../../../../overmind";

import LoadingSection from "../../../../../components/Common/LoadingSection/LoadingSection";
import FormWizard from "../../../../../components/Common/CustomForm/FormWizard";
import CustomForm from "../../../../../components/Common/CustomForm/Form";
import Button from "../../../../../components/Common/Button/Button";
import {clearSpaces, dateBrToISO} from "../../../../../renders/utils";
import ModalForm from "../../../../../components/Common/Modals/ModalForm";
import {deviceTypesOptions} from "../../../../../constants/formLists";
import {copyTextToClipboard} from "../../../../../common/clipboard";
import {UserCan} from "../../../../../helpers/gestora/auth";

const formatOnlyFirstName = (fieldProps, fieldValue) => {
	if (fieldProps.type=='text') {
		return clearSpaces(fieldValue, fieldProps.allowNumbers)
	}
	return fieldValue
}

const possibleFields = [
	{id:'motherName', caption:'1º Nome de sua mãe', type:'text', required:true, col:12,
		customFormatter:formatOnlyFirstName, allowNumbers:true,
		onKeyDown:e => {
			if (e.keyCode===32) {
				e.preventDefault()
			}
		}
	},,
	{id:'cep', caption:'CEP', type:'cep', required:true, col:12},
	{id:'birthDate', caption:'Data de nascimento', type:'date', required:true,col:12,},
	{id:'streetNumber', caption:'Número de sua Residência', type:'text', required:true, col:12, maxLength:40, allowNumbers : true,},
	{id:'email', caption:'E-mail',type:'email',required:true,col:12,},
]

const BadgeDevice = ({caption}) => {
	return <>
		<div className="badge-device badge p-2 pt-1 pb-1 me-1 align-items-center justify-content-center d-flex">
			<div className="p-0">{caption}</div>
		</div>
	</>
}

function UnblockDevices(props) {

	const formId = 'UnBlockDevicesForm'

	const client = useOvermind('clients')
	const security = useOvermind('security')
	const form = useOvermindForm(formId)
	const forms = useOvermind('forms')

	const [loading, setLoading] = useState(true)
	const [submitting, setSubmitting] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [blockedDevices, setBlockedDevices] = useState([])
	const [canUnlockAdmin, setCanUnlockAdmin] = useState(null)
	// const [temporaryPassword, setTemporaryPassword] = useState(null)

	useEffect(() => {
		setLoading(true)
		security.actions
			.listBlockedDevices(client?.state?.currentRegistrationForm?.form?.client?.id)
			.then(({devices, canUnlockAdmin}) => {
				setBlockedDevices(devices)
				setCanUnlockAdmin(canUnlockAdmin)
			})
			.catch(e => {
				alert(`Ocorreu um erro ao carregar os dados`)
			})
			.finally(() => setLoading(false))

	}, [])

	const fields = useMemo(() => {

		const selectedFields = canUnlockAdmin
			? _.sampleSize(
				possibleFields.reduce((map, obj) => {
					map.push(obj)
					return map
				}, []) , 3)
			: [];

		return [
			{
				id:'title',
				type:'title',
				caption:"Desbloqueio de dispositivos",
				subtitle : canUnlockAdmin
					? "Informe as respostas corretamente para gerar uma nova senha"
					: "Desbloqueio por Admin, certifique-se de averiguar os documentos enviados por e-mail."
			},
			{
				id:'deviceTypes',
				caption:'Tipos de dispositivos a desbloquear',
				type:'select2multi',
				required:true,
				options:deviceTypesOptions,
				col:12
			},
			...selectedFields
			//...possibleFields.filter(el => selectedFields.includes(el.id))
		]
	}, [canUnlockAdmin])

	const handleCopyPassword = () => {
		copyTextToClipboard(client.state.temporaryPassword,
			() => alert(`Copiado!`),
			() => alert(`Ocorreu um erro ao copiar para área de transferência`),
		)
	}
	const handleUnblockDevice = () => {
		console.log(blockedDevices)
		setLoading(true)
		forms.actions
			.setFormModel({formId, model:{deviceTypes:blockedDevices}})
			.then(() => forms.actions.incFormValidatedCount({formId}))
			.then(() => setShowModal(true))
			.finally(() => setLoading(false))
	}

	const handleSubmit = (values) => {
		const deviceTypes = values.deviceTypes
		// const values = _.cloneDeep(form.state.model)


		const answers = values
		if (answers.birthDate)
			answers.birthDate = dateBrToISO(answers.birthDate)

		setSubmitting(true)

		security.actions
			.unblockDevices({
				clientId:client?.state?.currentRegistrationForm?.form?.client?.id,
				answers,
				deviceTypes,
				forceByAdmin:!canUnlockAdmin && UserCan('unblockDeviceAdmin.edit'),
			})
			.then(({temporaryPassword, remainingBlockedDevices}) => {
				alert(`Aparelhos desbloqueados!`);
				setLoading(true)
				client.actions.setTemporaryPassword(temporaryPassword)
				setBlockedDevices(remainingBlockedDevices)
				setShowModal(false)
				setTimeout(() => setLoading(false))
			})
			.catch(error => {
				client.actions.setTemporaryPassword('')
				const message = error?.response?.data?.message
				const {canUnlockAdmin=true} = error.response.data || {}
				if (!canUnlockAdmin || message === 'max_tries_reached') {
					alert(`Número máximo de tentativas atingido.`);
					setCanUnlockAdmin(canUnlockAdmin)
					setShowModal(false)
				} else if (message === 'invalid_answers') {
					alert("As respostas digitadas estão incorretas.");
				} else {
					alert(`Ocorreu um erro ao salvar os dados`)
				}
			})
			.finally(() => setSubmitting(false))
	}


	return (
		<>
			<ModalForm
				formId={formId}
				data={null}
				isVisible={showModal}
				fields={fields}
				saving={submitting}
				confirmBtnCaption="Desbloquear"
				modalStyle={{
					maxWidth : '50%',
				}}
				onCancel={() => setShowModal(false)}
				onConfirm={handleSubmit}
			/>

			<LoadingSection loading={loading}>

				<div className="d-flex flex-row justify-content-center " >
					<div className="p-4 debug2">
						<div md={12} className="debug3 d-flex align-items-center justify-content-center debug">
							{
								blockedDevices.length
									? <li
										className="debug4 fas fa-lock font-size-rem13 "
										style={{
											color : !canUnlockAdmin ? "var(--bs-danger)" : undefined
										}}
									/>
									: <li className="debug4 fas fa-lock-open font-size-rem13 "/>
							}
						</div>
						{(!canUnlockAdmin && blockedDevices.length) &&
							<div className="text-danger text-align-center pt-1">Número máximo de tentativas atingido!</div>
						}
						<div md={12} className="debug3 d-flex align-items-center justify-content-center pt-3 font-size-rem2 debug">
							{
								blockedDevices.length
									? `Conta bloqueada nos dispositivos:`
									: `Conta está desbloqueada!`
							}
						</div>
						<div md={12} className="debug3 d-flex align-items-center justify-content-center py-2 debug">
							{blockedDevices.map(blockedDevice => {
								return <BadgeDevice key={blockedDevice} caption={deviceTypesOptions.find(el => el.id===blockedDevice).caption}/>
							})}
						</div>
						{(blockedDevices?.length>0 && UserCan(['unblockDevice.edit', 'unblockDeviceAdmin.edit'], true)) &&
						<div className="debug3">

							{(!canUnlockAdmin && !UserCan('unblockDeviceAdmin.edit'))
								?
								<div md={12} className="d-flex flex-column debug align-items-center">
									<div className="ps-2 pt-2">
										<div className="">Solicite ao Cliente que envie um email:</div>
										<div className="py-2">
											<div>Para: <strong>compliance@fiduc.com.br</strong></div>
											<div>Assunto: <strong>Desbloqueio de acesso</strong></div>
										</div>
										<div>Contendo os seguintes documentos:</div>
										<div>
											<ul>
											<li>Uma foto de rosto com o documento ao lado (documento com foto)</li>
											<li>Um comprovante de endereço (com vencimento menor que 90 dias)</li>
											</ul>
										</div>
									</div>
								</div>
								: <div md={12} className="d-flex align-items-center justify-content-center debug">
									<Button
										className={"btn btn-primary"}
										onClick={handleUnblockDevice}
										style={{margin:'1em'}}
									>
										Desbloquear e gerar nova senha
									</Button>
								</div>
							}


						</div>
						}

					</div>
					{(client.state.temporaryPassword) &&
					<div className="p-4 debug2 bg-light rounded-3">
						<div md={12} className="d-flex align-items-center justify-content-center ">
							<li className="fas fa-key font-size-rem13 "/>
						</div>
						<div md={12} className="d-flex align-items-center justify-content-center pt-3 font-size-rem2">
							Senha temporária
						</div>
						<div md={12} className="d-flex align-items-center justify-content-center pt-2 font-weight-700 font-family-title font-size-rem8 text-danger">
							{client.state.temporaryPassword}
						</div>

						<div md={12} className="d-flex align-items-center justify-content-center pt-2 font-weight-700 font-family-title font-size-rem8 ">
							<Button
								title={"Copiar para área de transferência"}
								color={`primary ms-1 `}
								onClick={() => handleCopyPassword()}
							>
								<i className="far fa-copy font-size-12 me-2" />
								Copiar
							</Button>

						</div>

					</div>
					}
				</div>

			</LoadingSection>
		</>
	)
}

export default UnblockDevices;
