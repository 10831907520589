import React, {useEffect, useMemo, useState} from "react"

import {
	Col,
	Row,
	Card, CardBody, CardTitle, CardText, TabPane, NavLink, NavItem, TabContent, Nav,
	Collapse,
} from "reactstrap"

//import { registrationFormScreens } from "./Configuration"

import {useOvermind, useOvermindForm} from "../../../../../overmind";

import LoadingSection from "../../../../../components/Common/LoadingSection/LoadingSection";
import FormWizard from "../../../../../components/Common/CustomForm/FormWizard";
import CustomForm from "../../../../../components/Common/CustomForm/Form";
import Button from "../../../../../components/Common/Button/Button";
import {clearSpaces, dateBrToISO} from "../../../../../renders/utils";
import ModalForm from "../../../../../components/Common/Modals/ModalForm";
import {deviceTypesOptions} from "../../../../../constants/formLists";
import {copyTextToClipboard} from "../../../../../common/clipboard";
import {UserCan} from "../../../../../helpers/gestora/auth";

const formatOnlyFirstName = (fieldProps, fieldValue) => {
	if (fieldProps.type=='text') {
		return clearSpaces(fieldValue, fieldProps.allowNumbers)
	}
	return fieldValue
}

const possibleFields = [
	{id:'motherName', caption:'1º Nome de sua mãe', type:'text', required:true, col:12,
		customFormatter:formatOnlyFirstName, allowNumbers:true,
		onKeyDown:e => {
			if (e.keyCode===32) {
				e.preventDefault()
			}
		}
	},,
	{id:'cep', caption:'CEP', type:'cep', required:true, col:12},
	{id:'birthDate', caption:'Data de nascimento', type:'date', required:true,col:12,},
	{id:'streetNumber', caption:'Número de sua Residência', type:'text', required:true, col:12, maxLength:40, allowNumbers : true,},
	{id:'email', caption:'E-mail',type:'email',required:true,col:12,},
]

const BadgeDevice = ({caption}) => {
	return <>
		<div className="badge-device badge p-2 pt-1 pb-1 me-1 align-items-center justify-content-center d-flex">
			<div className="p-0">{caption}</div>
		</div>
	</>
}

function ChangeClientPassword(props) {

	const client = useOvermind('clients')
	const security = useOvermind('security')
	const forms = useOvermind('forms')

	const [submitting, setSubmitting] = useState(false)
	const [temporaryPassword, setTemporaryPassword] = useState(null)

	const fields = useMemo(() => {

		return [
			{
				id:'title',
				type:'title',
				caption:"Desbloqueio de dispositivos",
			},
			{
				id:'deviceTypes',
				caption:'Tipos de dispositivos a desbloquear',
				type:'select2multi',
				required:true,
				options:deviceTypesOptions,
				col:12
			},
		]
	}, [])

	const handleCopyPassword = () => {
		copyTextToClipboard(temporaryPassword,
			() => alert(`Copiado!`),
			() => alert(`Ocorreu um erro ao copiar para área de transferência`),
		)
	}

	const handleGeneratePassword = (values) => {

		if (!confirm(`Tem certeza que deseja gerar uma nova senha temporária para este cliente?`))
			return

		setSubmitting(true)

		security.actions
			.changeClientPassword({
				clientId:client?.state?.currentRegistrationForm?.form?.client?.id,
			})
			.then(({temporaryPassword}) => {
				alert(`Nova senha temporária gerada!`);
				setTemporaryPassword(temporaryPassword)
			})
			.catch(error => {
				setTemporaryPassword('')
				alert(`Ocorreu um erro ao tentar gerar a senha`)
			})
			.finally(() => setSubmitting(false))
	}


	return (
		<>
			<div className="d-flex flex-row justify-content-center " >

				<div className="p-4 debug2 bg-light rounded-3">
					<div md={12} className="d-flex align-items-center justify-content-center ">
						<li className="fas fa-key font-size-rem13 "/>
					</div>
					<div md={12} className="d-flex align-items-center justify-content-center pt-3 font-size-rem2">
						{temporaryPassword ? "Senha temporária" : "Gerar nova senha temporária"}
					</div>

					{temporaryPassword &&
					<div md={12} className="d-flex align-items-center justify-content-center pt-2 font-weight-700 font-family-title font-size-rem8 text-danger">
						{temporaryPassword}
					</div>
					}


					<div md={12} className="d-flex align-items-center justify-content-center pt-2 font-weight-700 font-family-title font-size-rem8 ">
						{temporaryPassword
							? <Button
								title={"Copiar para área de transferência"}
								color={`primary ms-1 `}
								onClick={() => handleCopyPassword()}
							>
								<i className="far fa-copy font-size-12 me-2" />
								Copiar
							</Button>
							: <Button
								className={"btn btn-primary"}
								onClick={handleGeneratePassword}
								style={{margin:'1em'}}
							>
								Gerar nova senha
							</Button>
						}

					</div>

				</div>

			</div>

		</>
	)
}

export default ChangeClientPassword;
